import axios, { AxiosResponse } from 'axios'
import { SET_SCREENS, SET_SCREEN, SET_ERRORMSG, SET_REFRESH_SCREEN } from '../mutation_types'
import {
    CLEAR_SCREEN_ISLOADING,
    DELETE_SCREEN,
    EDIT_SCREEN,
    GET_SCREENS,
    ADD_SCREEN,
    GET_SCREEN,
    REFRESH_SCREEN,
} from '../action_types'
import { StateObject } from '@/dtos/state'
import Screen from '@/services/screen'
import { Module } from 'vuex'
import { EditScreen, AddScreen, GetScreen, RefreshScreen } from '@/dtos/screen.dto'
import { GetUser } from '@/dtos/users.dto'

const screen: Module<any, {}> = {
    state: {
        screen: {
            data: null,
            isLoading: false
        },
        screens: {
            data: null,
            isLoading: false
        },
        refreshScreen: {
            data: null,
            isLoading: false
        },
    },
    getters: {
        screen: (state: { screen: any }) => state.screen,
        screens: (state: { screens: any }) => state.screens,
        refreshScreen: (state: { refreshScreen: any }) => state.refreshScreen
    },
    mutations: {
        [SET_SCREEN]: (state: { screen: StateObject }, payload: { data: null; status: any; isLoading: any }) => { payload.data != null ? state.screen.data = payload.data : null, state.screen.isLoading = payload.isLoading },
        [SET_SCREENS]: (state: { screens: StateObject }, payload: { data: null; status: any; isLoading: any }) => { payload.data != null ? state.screens.data = payload.data : null, state.screens.isLoading = payload.isLoading },
        [SET_REFRESH_SCREEN]: (state: { refreshScreen: StateObject }, payload: { data: null; status: any; isLoading: any }) => { payload.data != null ? state.refreshScreen.data = payload.data : null, state.refreshScreen.isLoading = payload.isLoading }
    },
    actions: {
        [ADD_SCREEN]: async({ commit }: any, payload: FormData) => {
            commit(SET_SCREEN, { data: null, isLoading: true })

            let result: AxiosResponse = await Screen.registerScreen(payload)
          
            if(result) {
                if (result.data.status) {
                    commit(SET_SCREEN, { data: result.data.data, isLoading: false })
                    return true;
                }
                commit(SET_SCREEN, { data: null, isLoading: false })
                commit(SET_ERRORMSG, result.data.msg)
                return false;
            } else {
                commit(SET_SCREEN, { isLoading: false })
                return false;
            }
        },
        [GET_SCREENS]: async({ commit }: any, payload: any) => {
            commit(SET_SCREENS, { data: null, isLoading: true })

            let result: AxiosResponse = await Screen.getScreens(payload as GetScreen)
          
            if(result) {
                if (result.data.status) {
                    console.log('result.data.data', result.data.data)
                    commit(SET_SCREENS, { data: result.data.data, isLoading: false })
                    return true;
                }
                commit(SET_SCREENS, { data: null, isLoading: false })
                commit(SET_ERRORMSG, result.data.msg)
                return false;
            } else {
                commit(SET_SCREENS, { isLoading: false })
                return false;
            }
        },
        [GET_SCREEN]: async({ commit }: any, payload: any) => {
            commit(SET_SCREEN, { data: null, isLoading: true })

            let result: AxiosResponse = await Screen.getScreens(payload as GetScreen)
          
            if(result) {
                if (result.data.status) {
                    console.log('result.data.data', result.data.data)
                    commit(SET_SCREEN, { data: result.data.data[0], isLoading: false })
                    return true;
                }
                commit(SET_SCREEN, { data: null, isLoading: false })
                commit(SET_ERRORMSG, result.data.msg)
                return false;
            } else {
                commit(SET_SCREEN, { isLoading: false })
                return false;
            }
        },
        [EDIT_SCREEN]: async({ commit }: any, payload: any) => {
            commit(SET_SCREEN, { data: null, isLoading: true })

            let result: AxiosResponse = await Screen.editScreen(payload as EditScreen)
          
            if(result) {
                if (result.data.status) {
                    console.log('result.data.data', result.data.data)
                    commit(SET_SCREEN, { data: result.data.data, isLoading: false })
                    return true;
                }
                commit(SET_SCREEN, { data: null, isLoading: false })
                commit(SET_ERRORMSG, result.data.msg)
                return false;
            } else {
                commit(SET_SCREEN, { isLoading: false })
                return false;
            }
        },
        [REFRESH_SCREEN]: async ({ commit }: any, payload: any) => {
            console.log('payload.scrpayload.scr', payload.scr)
            commit(SET_REFRESH_SCREEN, { data: { id: payload.scr }, isLoading: true })

            let result: AxiosResponse = await Screen.refreshScreen(payload as RefreshScreen)
          
            if(result) {
                if (result.data.status) {

                    commit(SET_REFRESH_SCREEN, { data: { id: payload.scr }, isLoading: false })
                    return true;
                }
                commit(SET_REFRESH_SCREEN, { data: { id: payload.scr }, isLoading: false })
                commit(SET_ERRORMSG, result.data.msg)
                return false;
            } else {
                commit(SET_REFRESH_SCREEN, { data: { id: payload.scr }, isLoading: false })
                return false;
            }
        },
        [DELETE_SCREEN]: async({ commit }: any, payload: string) => {
            commit(SET_SCREEN, { data: null, isLoading: true })

            let result: AxiosResponse = await Screen.deleteScreen(payload)
          
            if(result) {
                if (result.data.status) {
                    console.log('result.data.data', result.data.data)
                    commit(SET_SCREEN, { data: result.data.data, isLoading: false })
                    return true;
                }
                commit(SET_SCREEN, { data: null, isLoading: false })
                commit(SET_ERRORMSG, result.data.msg)
                return false;
            } else {
                commit(SET_SCREEN, { isLoading: false })
                return false;
            }
        },
        [CLEAR_SCREEN_ISLOADING]: ({ commit }) => {
            commit(SET_SCREEN, { isLoading: false })
            commit(SET_SCREENS, { isLoading: false })
        }
    }
}

export default screen