import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import multiguard from 'vue-router-multiguard';
import store from '@/store';
import Dashboard from '../views/Dashboard.vue'
import { ACCOUNT_ROUTE, ALERT_ROUTE, ANNOUNCEMENTS_ROUTE, DASHBOARD_ROUTE, DISPLAY_ROUTE, DONATIONS_ROUTE, LOGIN_ROUTE, ORGANIZATION_ACCOUNTS_ROUTE, ORGANIZATION_ANNOUNCEMENTS_ROUTE, ORGANIZATION_DETAILS_ROUTE, ORGANIZATION_DONATIONS_ROUTE, ORGANIZATION_MEMBERS_ROUTE, ORGANIZATION_MINYANIM_ROUTE, ORGANIZATION_NOTIFICATIONS_ROUTE, ORGANIZATION_SCREENS_ROUTE, ORGANIZATION_TEMPLATES_ROUTE, ORGANIZATIONS_ROUTE, SCREENS_ROUTE, TEMPLATES_ROUTE, TICKETS_ROUTE } from './routes'
import { ifNotAuthDeny } from './guards/not_auth'
import { ifAuthDeny } from './guards/auth'
import { clearDisplayDonations, updateAuthAccount } from './router_methods'
import { CLEAR_ERRORMSG } from '@/store/action_types';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: DASHBOARD_ROUTE,
    beforeEnter: ifNotAuthDeny,
    component: Dashboard
  },
  {
    path: '/login',
    name: LOGIN_ROUTE,
    beforeEnter: ifAuthDeny,
    component: () => import('@/views/auth/Login2.vue')
  },
  {
    path: '/display/:id/:org',
    name: DISPLAY_ROUTE,
    beforeEnter: multiguard([ifNotAuthDeny, clearDisplayDonations]),
    component: () => import('@/views/display/Display.vue')
  },
  {
    path: '/donations',
    name: DONATIONS_ROUTE,
    beforeEnter: ifNotAuthDeny,
    component: () => import('@/views/Donations.vue')
  },
  {
    path: '/templates',
    name: TEMPLATES_ROUTE,
    beforeEnter: ifNotAuthDeny,
    component: () => import('@/views/Templates.vue')
  },
  {
    path: '/screens',
    name: SCREENS_ROUTE,
    beforeEnter: ifNotAuthDeny,
    component: () => import('@/views/Display_Screens.vue')
  },
  {
    path: '/announcement',
    name: ANNOUNCEMENTS_ROUTE,
    beforeEnter: ifNotAuthDeny,
    component: () => import('@/views/Announcements.vue')
  },
  {
    path: '/organizations',
    name: ORGANIZATIONS_ROUTE,
    beforeEnter: ifNotAuthDeny,
    component: () => import('@/views/organization/Organizations.vue')
  },
  {
    path: '/organizations/:id/details',
    name: ORGANIZATION_DETAILS_ROUTE,
    beforeEnter: ifNotAuthDeny,
    component: () => import('@/views/organization/Details.vue')
  },
  {
    path: '/organizations/:id/accounts',
    name: ORGANIZATION_ACCOUNTS_ROUTE,
    beforeEnter: ifNotAuthDeny,
    component: () => import('@/views/organization/Accounts.vue')
  },
  {
    path: '/organizations/:id/donations',
    name: ORGANIZATION_DONATIONS_ROUTE,
    beforeEnter: ifNotAuthDeny,
    component: () => import('@/views/organization/Donations.vue')
  },
  {
    path: '/organizations/:id/minyanim',
    name: ORGANIZATION_MINYANIM_ROUTE,
    beforeEnter: ifNotAuthDeny,
    component: () => import('@/views/organization/Minyanim.vue')
  },
  {
    path: '/organizations/:id/announcements',
    name: ORGANIZATION_ANNOUNCEMENTS_ROUTE,
    beforeEnter: ifNotAuthDeny,
    component: () => import('@/views/organization/Announcements.vue')
  },
  {
    path: '/organizations/:id/screens',
    name: ORGANIZATION_SCREENS_ROUTE,
    beforeEnter: ifNotAuthDeny,
    component: () => import('@/views/organization/Screens.vue')
  },
  {
    path: '/organizations/:id/members',
    name: ORGANIZATION_MEMBERS_ROUTE,
    beforeEnter: ifNotAuthDeny,
    component: () => import('@/views/organization/Members.vue')
  },
  {
    path: '/organizations/:id/templates',
    name: ORGANIZATION_TEMPLATES_ROUTE,
    beforeEnter: ifNotAuthDeny,
    component: () => import('@/views/organization/Templates.vue')
  },
  {
    path: '/organizations/:id/notifications',
    name: ORGANIZATION_NOTIFICATIONS_ROUTE,
    beforeEnter: ifNotAuthDeny,
    component: () => import('@/views/organization/Notifications.vue')
  },
  {
    path: '/accounts',
    name: ACCOUNT_ROUTE,
    beforeEnter: ifNotAuthDeny,
    component: () => import('@/views/Users.vue')
  },
  {
    path: '/alerts',
    name: ALERT_ROUTE,
    beforeEnter: ifNotAuthDeny,
    component: () => import('@/views/Alerts.vue')
  },
  {
    path: '/tickets',
    name: TICKETS_ROUTE,
    beforeEnter: ifNotAuthDeny,
    component: () => import('@/views/Tickets.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  if (to.name !== LOGIN_ROUTE && from.name !== LOGIN_ROUTE) {
    
  }

  store.dispatch(CLEAR_ERRORMSG)

  next()
})

export default router