import axios, { AxiosResponse } from 'axios'
import { SET_MINYANIM_TYPES, SET_MINYANIM_TYPE, SET_ERRORMSG } from '../mutation_types'
import {
    CLEAR_MINYANIM_TYPES_ISLOADING,
    DELETE_MINYANIM_TYPE,
    EDIT_MINYANIM_TYPE,
    GET_MINYANIM_TYPES,
    ADD_MINYANIM_TYPE,
} from '../action_types'
import { StateObject } from '@/dtos/state'
import MinyanimType from '@/services/minyanim_type'
import { Module } from 'vuex'
import { EditMinyanimType, AddMinyanimType, GetMinyanimType } from '@/dtos/minyanim_type.dto'

const minyanimType: Module<any, {}> = {
    state: {
        minyanimType: {
            data: null,
            isLoading: false
        },
        minyanimTypes: {
            data: null,
            isLoading: false
        }
    },
    getters: {
        minyanimType: (state: { minyanimType: any }) => state.minyanimType,
        minyanimTypes: (state: { minyanimTypes: any }) => state.minyanimTypes
    },
    mutations: {
        [SET_MINYANIM_TYPE]: (state: { minyanimType: StateObject }, payload: { data: null; status: any; isLoading: any }) => { payload.data != null ? state.minyanimType.data = payload.data : null, state.minyanimType.isLoading = payload.isLoading },
        [SET_MINYANIM_TYPES]: (state: { minyanimTypes: StateObject }, payload: { data: null; status: any; isLoading: any }) => { payload.data != null ? state.minyanimTypes.data = payload.data : null, state.minyanimTypes.isLoading = payload.isLoading }
    },
    actions: {
        [ADD_MINYANIM_TYPE]: async({ commit }: any, payload: any) => {
            commit(SET_MINYANIM_TYPE, { data: null, isLoading: true })

            let result: AxiosResponse = await MinyanimType.registerMinyanimType(payload as AddMinyanimType)
          
            if(result) {
                if (result.data.status) {
                    commit(SET_MINYANIM_TYPE, { data: result.data.data, isLoading: false })
                    return true;
                }
                commit(SET_MINYANIM_TYPE, { data: null, isLoading: false })
                commit(SET_ERRORMSG, result.data.msg)
                return false;
            } else {
                commit(SET_MINYANIM_TYPE, { isLoading: false })
                return false;
            }
        },
        [GET_MINYANIM_TYPES]: async({ commit }: any, payload: any) => {
            commit(SET_MINYANIM_TYPES, { data: null, isLoading: true })

            let result: AxiosResponse = await MinyanimType.getMinyanimTypes(payload as GetMinyanimType)
          
            if(result) {
                if (result.data.status) {
                    console.log('result.data.data', result.data.data)
                    commit(SET_MINYANIM_TYPES, { data: result.data.data, isLoading: false })
                    return true;
                }
                commit(SET_MINYANIM_TYPES, { data: null, isLoading: false })
                commit(SET_ERRORMSG, result.data.msg)
                return false;
            } else {
                commit(SET_MINYANIM_TYPES, { isLoading: false })
                return false;
            }
        },
        [EDIT_MINYANIM_TYPE]: async({ commit }: any, payload: any) => {
            commit(SET_MINYANIM_TYPE, { data: null, isLoading: true })

            let result: AxiosResponse = await MinyanimType.editMinyanimType(payload as EditMinyanimType)
          
            if(result) {
                if (result.data.status) {
                    console.log('result.data.data', result.data.data)
                    commit(SET_MINYANIM_TYPE, { data: result.data.data, isLoading: false })
                    return true;
                }
                commit(SET_MINYANIM_TYPE, { data: null, isLoading: false })
                commit(SET_ERRORMSG, result.data.msg)
                return false;
            } else {
                commit(SET_MINYANIM_TYPE, { isLoading: false })
                return false;
            }
        },
        [DELETE_MINYANIM_TYPE]: async({ commit }: any, payload: string) => {
            commit(SET_MINYANIM_TYPE, { data: null, isLoading: true })

            let result: AxiosResponse = await MinyanimType.deleteMinyanimType(payload)
          
            if(result) {
                if (result.data.status) {
                    console.log('result.data.data', result.data.data)
                    commit(SET_MINYANIM_TYPE, { data: result.data.data, isLoading: false })
                    return true;
                }
                commit(SET_MINYANIM_TYPE, { data: null, isLoading: false })
                commit(SET_ERRORMSG, result.data.msg)
                return false;
            } else {
                commit(SET_MINYANIM_TYPE, { isLoading: false })
                return false;
            }
        },
        [CLEAR_MINYANIM_TYPES_ISLOADING]: ({ commit }) => {
            commit(SET_MINYANIM_TYPE, { isLoading: false })
            commit(SET_MINYANIM_TYPES, { isLoading: false })
        }
    }
}

export default minyanimType