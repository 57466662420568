import baseAPI from '@/api/base';
import { EditUser, GetUser, RegisterUser } from '../dtos/users.dto';
import { DELETE_USER_URL, EDIT_USER_URL, GET_USER_URL, REGISTER_USER_URL, TOGGLE_USER_IS_ACTIVE_URL } from '@/utils/urls';
import { AxiosResponse } from 'axios';

class User {
    static getUsers = async(getUser: GetUser) : Promise<AxiosResponse> => {
        return new Promise(async(resolve, reject) => {
            try {
                let response: AxiosResponse = await baseAPI.get(GET_USER_URL,{
                    params: getUser
                })
                console.log('response', response)
                resolve(response)
            } catch (error) {
                reject(error)
            }
        })
    }

   
    static registerUser = async(registerUser: RegisterUser) : Promise<AxiosResponse> => {
        return new Promise(async(resolve, reject) => {
            try {
                let response: AxiosResponse = await baseAPI.post(REGISTER_USER_URL, {
                    ...registerUser
                })
                console.log('response', response)
                resolve(response)
            } catch (error) {
                reject(error)
            }
        })
    }

    static editUser = async(editUser: EditUser) : Promise<AxiosResponse> => {
        return new Promise(async(resolve, reject) => {
            try {
                let response: AxiosResponse = await baseAPI.put(EDIT_USER_URL, {
                    ...editUser
                })
                resolve(response)
            } catch (error) {
                reject(error)
            }
        })
    }

    static toggleUserIsActive = async(editUser: EditUser) : Promise<AxiosResponse> => {
        return new Promise(async(resolve, reject) => {
            try {
                let response: AxiosResponse = await baseAPI.put(TOGGLE_USER_IS_ACTIVE_URL, {
                    ...editUser
                })
                resolve(response)
            } catch (error) {
                reject(error)
            }
        })
    }

    static deleteUser = async(userId: string) : Promise<AxiosResponse> => {
        return new Promise(async(resolve, reject) => {
            try {
                let response: AxiosResponse = await baseAPI.delete(`${DELETE_USER_URL}/${userId}`)

                resolve(response)
            } catch (error) {
                reject(error)
            }
        })
    }
}

export default User