// account
export const SIGNUP_URL = '/auth/signup'
export const LOGIN_URL = '/auth/login'

// dashboard
export const DASHBOARD_URL = '/dashboard'

// donation
// export const DONATION_URL = '/donation'
export const DONATION_URL = '/donation/old'
export const CALENDAR_DONATION_URL = '/donation/calendar'
export const REGISTER_DONATION_URL = '/donation/register'
export const EDIT_DONATION_URL = '/donation/edit'
export const TOGGLE_DONATION_IS_ACTIVE_URL = '/donation/toggleIsActive'
export const DELETE_DONATION_URL = '/donation'

// templates
export const TEMPLATE_URL = '/template'
export const ADD_TEMPLATE_URL = '/template/add'
export const EDIT_TEMPLATE_URL = '/template/edit'
export const DELETE_TEMPLATE_URL = '/template'

// announcement
export const ANNOUNCEMENT_URL = '/announcement'
export const ADD_ANNOUNCEMENT_URL = '/announcement/add'
export const EDIT_ANNOUNCEMENT_URL = '/announcement/edit'
export const DELETE_ANNOUNCEMENT_URL = '/announcement'

// screen
export const SCREEN_URL = '/screen'
export const ADD_SCREEN_URL = '/screen/add'
export const EDIT_SCREEN_URL = '/screen/edit'
export const DELETE_SCREEN_URL = '/screen'
export const REFRESH_SCREEN_URL = '/screen/refresh'

// organizations
export const ORGANIZATION_URL = '/organization'
export const REGISTER_ORGANIZATION_URL = '/organization/register'
export const EDIT_ORGANIZATION_URL = '/organization/edit'
export const TOGGLE_ORGANIZATION_IS_ACTIVE_URL = '/organization/toggleIsActive'
export const DELETE_ORGANIZATION_URL = '/organization'

// user
export const GET_USER_URL = '/account'
export const REGISTER_USER_URL = '/account/register'
export const EDIT_USER_URL = '/account/edit'
export const TOGGLE_USER_IS_ACTIVE_URL = '/account/toggleIsActive'
export const DELETE_USER_URL = '/account'

// member
export const MEMBER_URL = '/member'
export const ADD_MEMBER_URL = '/member/add'
export const EDIT_MEMBER_URL = '/member/edit'
export const DELETE_MEMBER_URL = '/member'

// purpose
export const PURPOSE_URL = '/purpose'
export const ADD_PURPOSE_URL = '/purpose/add'
export const EDIT_PURPOSE_URL = '/purpose/edit'
export const DELETE_PURPOSE_URL = '/purpose'

// notification
export const NOTIFICATION_URL = '/notification'
export const CLEAR_NOTIFICATION_URL = '/notification/clear'

// minyanim
export const MINYANIM_URL = '/minyanim'
export const DISPLAY_MINYANIM_URL = '/minyanim/display'
export const ADD_MINYANIM_URL = '/minyanim/add'
export const EDIT_MINYANIM_URL = '/minyanim/edit'
export const TOGGLE_MINYANIM_IS_ACTIVE_URL = '/minyanim/toggleIsActive'
export const DELETE_MINYANIM_URL = '/minyanim'

// room
export const ROOM_URL = '/room'
export const REGISTER_ROOM_URL = '/room/register'
export const EDIT_ROOM_URL = '/room/edit'
export const DELETE_ROOM_URL = '/room'

// alert
export const ALERT_URL = '/alert'
export const ADD_ALERT_URL = '/alert/add'
export const EDIT_ALERT_URL = '/alert/edit'
export const DELETE_ALERT_URL = '/alert'

// alert_notification
export const ALERT_NOTIFICATION_URL = '/alertNotification'
export const VIEW_ALERT_NOTIFICATION_URL = '/alertNotification/view'

// ticket
export const TICKET_URL = '/ticket'
export const ADD_TICKET_URL = '/ticket/add'
export const EDIT_TICKET_URL = '/ticket/edit'
export const CLOSE_TICKET_URL = '/ticket/close'
export const DELETE_TICKET_URL = '/ticket'

// ticket
export const TICKET_COMMENT_URL = '/ticketComment'
export const ADD_TICKET_COMMENT_URL = '/ticketComment/add'
export const EDIT_TICKET_COMMENT_URL = '/ticketComment/edit'
export const DELETE_TICKET_COMMENT_URL = '/ticketComment'

// minyanim type
export const MINYANIM_TYPE_URL = '/minyanimType'
export const ADD_MINYANIM_TYPE_URL = '/minyanimType/add'
export const EDIT_MINYANIM_TYPE_URL = '/minyanimType/edit'
export const DELETE_MINYANIM_TYPE_URL = '/minyanimType'

// stats
export const GET_DASHBOARD_STATS_URL = '/stats/dashboard'