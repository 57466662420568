import store from "@/store"
import { CLEAR_ACCOUNT_ISLOADING, CLEAR_ANNOUNCEMENT_ISLOADING, CLEAR_MEMBERS_ISLOADING, CLEAR_MINYANIMS_ISLOADING, CLEAR_NOTIFICATIONS_ISLOADING, CLEAR_ORGANIZATION_ISLOADING, CLEAR_PURPOSE_ISLOADING, CLEAR_ROOMS_ISLOADING, CLEAR_SCREEN_ISLOADING, CLEAR_STATS_ISLOADING, CLEAR_TEMPLATE_ISLOADING, CLEAR_TICKET_COMMENTS_ISLOADING, CLEAR_TICKETS_ISLOADING, CLEAR_USERS_ISLOADING } from "@/store/action_types"
import { CLEAR_DONATION_ISLOADING } from '../store/action_types';

export const resetLoaders = () => {
    store.dispatch(CLEAR_ACCOUNT_ISLOADING)
    store.dispatch(CLEAR_ANNOUNCEMENT_ISLOADING)
    store.dispatch(CLEAR_DONATION_ISLOADING)
    store.dispatch(CLEAR_MEMBERS_ISLOADING)
    store.dispatch(CLEAR_MINYANIMS_ISLOADING)
    store.dispatch(CLEAR_NOTIFICATIONS_ISLOADING)
    store.dispatch(CLEAR_ORGANIZATION_ISLOADING)
    store.dispatch(CLEAR_PURPOSE_ISLOADING)
    store.dispatch(CLEAR_ROOMS_ISLOADING)
    store.dispatch(CLEAR_SCREEN_ISLOADING)
    store.dispatch(CLEAR_STATS_ISLOADING)
    store.dispatch(CLEAR_TEMPLATE_ISLOADING)
    store.dispatch(CLEAR_TICKETS_ISLOADING)
    store.dispatch(CLEAR_TICKET_COMMENTS_ISLOADING)
    store.dispatch(CLEAR_USERS_ISLOADING)
}