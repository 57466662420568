import axios, { AxiosResponse } from 'axios'
import { SET_ROOMS, SET_ROOM, SET_ERRORMSG } from '../mutation_types'
import {
    CLEAR_ROOMS_ISLOADING,
    DELETE_ROOM,
    EDIT_ROOM,
    GET_ROOMS,
    REGISTER_ROOM,
} from '../action_types'
import { StateObject } from '@/dtos/state'
import Room from '@/services/room'
import { Module } from 'vuex'
import { EditRoom, AddRoom, GetRoom } from '@/dtos/room.dto'

const room: Module<any, {}> = {
    state: {
        room: {
            data: null,
            isLoading: false
        },
        rooms: {
            data: null,
            isLoading: false
        }
    },
    getters: {
        room: (state: { room: any }) => state.room,
        rooms: (state: { rooms: any }) => state.rooms
    },
    mutations: {
        [SET_ROOM]: (state: { room: StateObject }, payload: { data: null; status: any; isLoading: any }) => { payload.data != null ? state.room.data = payload.data : null, state.room.isLoading = payload.isLoading },
        [SET_ROOMS]: (state: { rooms: StateObject }, payload: { data: null; status: any; isLoading: any }) => { payload.data != null ? state.rooms.data = payload.data : null, state.rooms.isLoading = payload.isLoading }
    },
    actions: {
        [REGISTER_ROOM]: async({ commit }: any, payload: any) => {
            commit(SET_ROOM, { data: null, isLoading: true })

            let result: AxiosResponse = await Room.registerRoom(payload as AddRoom)
          
            if(result) {
                if (result.data.status) {
                    commit(SET_ROOM, { data: result.data.data, isLoading: false })
                    return true;
                }
                commit(SET_ROOM, { data: null, isLoading: false })
                commit(SET_ERRORMSG, result.data.msg)
                return false;
            } else {
                commit(SET_ROOM, { isLoading: false })
                return false;
            }
        },
        [GET_ROOMS]: async({ commit }: any, payload: any) => {
            commit(SET_ROOMS, { data: null, isLoading: true })

            let result: AxiosResponse = await Room.getRooms(payload as GetRoom)
          
            if(result) {
                if (result.data.status) {
                    console.log('result.data.data', result.data.data)
                    commit(SET_ROOMS, { data: result.data.data, isLoading: false })
                    return true;
                }
                commit(SET_ROOMS, { data: null, isLoading: false })
                commit(SET_ERRORMSG, result.data.msg)
                return false;
            } else {
                commit(SET_ROOMS, { isLoading: false })
                return false;
            }
        },
        [EDIT_ROOM]: async({ commit }: any, payload: any) => {
            commit(SET_ROOM, { data: null, isLoading: true })

            let result: AxiosResponse = await Room.editRoom(payload as EditRoom)
          
            if(result) {
                if (result.data.status) {
                    console.log('result.data.data', result.data.data)
                    commit(SET_ROOM, { data: result.data.data, isLoading: false })
                    return true;
                }
                commit(SET_ROOM, { data: null, isLoading: false })
                commit(SET_ERRORMSG, result.data.msg)
                return false;
            } else {
                commit(SET_ROOM, { isLoading: false })
                return false;
            }
        },
        [DELETE_ROOM]: async({ commit }: any, payload: string) => {
            commit(SET_ROOM, { data: null, isLoading: true })

            let result: AxiosResponse = await Room.deleteRoom(payload)
          
            if(result) {
                if (result.data.status) {
                    console.log('result.data.data', result.data.data)
                    commit(SET_ROOM, { data: result.data.data, isLoading: false })
                    return true;
                }
                commit(SET_ROOM, { data: null, isLoading: false })
                commit(SET_ERRORMSG, result.data.msg)
                return false;
            } else {
                commit(SET_ROOM, { isLoading: false })
                return false;
            }
        },
        [CLEAR_ROOMS_ISLOADING]: ({ commit }) => {
            commit(SET_ROOM, { isLoading: false })
            commit(SET_ROOMS, { isLoading: false })
        }
    }
}

export default room