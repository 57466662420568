<template>
    <div class="sidenav">
        <br>
        <div class="row">
            <img src="../assets/images/logo_new.png" class="img-fluid col-10 offset-1" alt="">
        </div>
        <div class="links">
            <br>
            <ul class="">
                <li :class="[ route == DASHBOARD_ROUTE ? 'selected' : null, 'nav-items']"><router-link
                        :to="{ name: DASHBOARD_ROUTE }" class="router-link"><font-awesome-icon icon="fa-solid fa-grip"
                            class="fa" /> <span class="d-none d-lg-block">Dashboard</span></router-link>
                </li>
                <li v-if="account != null && account.role == USER_ROLE_ADMIN"
                    :class="[[ORGANIZATIONS_ROUTE, ORGANIZATION_DETAILS_ROUTE, ORGANIZATION_ACCOUNTS_ROUTE, ORGANIZATION_DONATIONS_ROUTE, ORGANIZATION_MINYANIM_ROUTE, ORGANIZATION_ANNOUNCEMENTS_ROUTE, ORGANIZATION_SCREENS_ROUTE, ORGANIZATION_MEMBERS_ROUTE, ORGANIZATION_TEMPLATES_ROUTE, ORGANIZATION_NOTIFICATIONS_ROUTE].includes(route) ? 'selected' : null, 'nav-items']">
                    <router-link :to="{ name: ORGANIZATIONS_ROUTE }" class="router-link"><font-awesome-icon
                            icon="fa-solid fa-sitemap" class="fa" /> <span
                            class="d-none d-lg-block">Organizations</span></router-link>
                </li>
                <li v-if="account != null && account.role == USER_ROLE_USER"
                    :class="[[ORGANIZATION_DETAILS_ROUTE, ORGANIZATION_ACCOUNTS_ROUTE, ORGANIZATION_SCREENS_ROUTE].includes(route) ? 'selected' : null, 'nav-items']">
                    <router-link :to="{ name: ORGANIZATION_DETAILS_ROUTE, params: { id: account.organization.id } }"
                        class="router-link"><font-awesome-icon icon="fa-solid fa-sitemap" class="fa" />
                        <span class="d-none d-lg-block">My Organization</span></router-link>
                </li>
                <!-- <li :class="[ route == DISPLAY_ROUTE ? 'selected' : null, 'nav-items']"><router-link
                        :to="{ name: DISPLAY_ROUTE }" target="_blank" class="router-link"><font-awesome-icon icon="fa-solid fa-code-branch" class="fa"/> Display
                        Screen</router-link></li> -->
                <li :class="[ route == DONATIONS_ROUTE ? 'selected' : null, 'nav-items']"><router-link
                        :to="{ name: DONATIONS_ROUTE }" class="router-link"><font-awesome-icon
                            icon="fa-solid fa-hand-holding-dollar" class="fa" /> <span
                            class="d-none d-lg-block">Donations</span></router-link>
                </li>
                <li :class="[ route == TEMPLATES_ROUTE ? 'selected' : null, 'nav-items']"><router-link
                        :to="{ name: TEMPLATES_ROUTE }" class="router-link"><font-awesome-icon
                            icon="fa-solid fa-layer-group" class="fa" /> <span
                            class="d-none d-lg-block">Templates</span></router-link>
                </li>
                <li :class="[route == SCREENS_ROUTE ? 'selected' : null, 'nav-items']"><router-link
                        :to="{ name: SCREENS_ROUTE }" class="router-link"><font-awesome-icon icon="fa-solid fa-display"
                            class="fa" /> <span class="d-none d-lg-block">Screens</span></router-link>
                </li>
                <li v-if="account != null && account.role == USER_ROLE_ADMIN"
                    :class="[route == ALERT_ROUTE ? 'selected' : null, 'nav-items']"><router-link
                        :to="{ name: ALERT_ROUTE }" class="router-link"><font-awesome-icon icon="fa-solid fa-bell"
                            class="fa" /> <span class="d-none d-lg-block">Alerts</span></router-link>
                </li>
                <!-- <li :class="[ route == ANNOUNCEMENTS_ROUTE ? 'selected' : null, 'nav-items']"><router-link
                        :to="{ name: ANNOUNCEMENTS_ROUTE }" class="router-link"><i
                            class="pi"></i>Announcements</router-link></li> -->
                <!-- <li :class="[ route == ACCOUNT_ROUTE ? 'selected' : null, 'nav-items']"><router-link :to="{ name: ACCOUNT_ROUTE }" class="router-link"><font-awesome-icon icon="fa-solid fa-code-branch" class="fa"/> Accounts</router-link></li> -->
            </ul>
        </div>
        <div class="footer">
            <router-link :to="{ name: TICKETS_ROUTE }" class="nav-items selected router-link"><font-awesome-icon
                    icon="fa-solid fa-circle-question" class="fa" /><span class="d-none d-lg-block">Support Chat</span></router-link>
        </div>
        <!-- <div class="logo-img">
            <h6 class="info">P: <span>732-607-8977</span></h6>
            <h6 class="info">E: <span>info@persim.us</span></h6>
            <img v-on:click="routeToWebsite()" src="../assets/images/logo-sidenav.png" class="col-12" alt="" srcset="">
        </div> -->
    </div>
</template>
<script>
import { DASHBOARD_ROUTE, DISPLAY_ROUTE, DONATIONS_ROUTE, TEMPLATES_ROUTE, ACCOUNT_ROUTE, ANNOUNCEMENTS_ROUTE, SCREENS_ROUTE, ORGANIZATIONS_ROUTE, ORGANIZATION_DETAILS_ROUTE, ORGANIZATION_ACCOUNTS_ROUTE, ORGANIZATION_DONATIONS_ROUTE, ORGANIZATION_MINYANIM_ROUTE, ORGANIZATION_ANNOUNCEMENTS_ROUTE, ORGANIZATION_SCREENS_ROUTE, ORGANIZATION_MEMBERS_ROUTE, ORGANIZATION_TEMPLATES_ROUTE, ORGANIZATION_NOTIFICATIONS_ROUTE, ALERT_ROUTE, TICKETS_ROUTE } from '@/router/routes'
import { LOGOUT } from '@/store/action_types'
import { WEBSITE_URL, DEFAULT_ERROR_MSG, TOAST_GLOBAL_CONFIG, USER_ROLE_ADMIN, USER_ROLE_USER } from '@/utils/const';

import { library } from '@fortawesome/fontawesome-svg-core'
import { faGrip, faSitemap, faHandHoldingDollar, faLayerGroup, faDisplay, faBell, faCircleQuestion } from '@fortawesome/free-solid-svg-icons'
import {  } from '@fortawesome/free-regular-svg-icons'
library.add(faGrip, faSitemap, faHandHoldingDollar, faLayerGroup, faDisplay, faBell, faCircleQuestion)

export default {
    data() {
        return {
            USER_ROLE_ADMIN,
            USER_ROLE_USER,
            DASHBOARD_ROUTE,
            DISPLAY_ROUTE,
            DONATIONS_ROUTE,
            TEMPLATES_ROUTE,
            SCREENS_ROUTE,
            ACCOUNT_ROUTE,
            ORGANIZATIONS_ROUTE,
            ORGANIZATION_DETAILS_ROUTE,
            ORGANIZATION_ACCOUNTS_ROUTE,
            ORGANIZATION_DONATIONS_ROUTE,
            ORGANIZATION_MINYANIM_ROUTE,
            ORGANIZATION_ANNOUNCEMENTS_ROUTE,
            ORGANIZATION_SCREENS_ROUTE,
            ORGANIZATION_MEMBERS_ROUTE,
            ORGANIZATION_TEMPLATES_ROUTE,
            ORGANIZATION_NOTIFICATIONS_ROUTE,
            ALERT_ROUTE,
            ANNOUNCEMENTS_ROUTE,
            TICKETS_ROUTE
        }
    },
    watch: {
        errorMessage: {
            handler(val) {
                if(val != null) {
                    this.$toast.error(val ?? DEFAULT_ERROR_MSG, TOAST_GLOBAL_CONFIG);
                }
            }
            
        }
    },
    computed: {
        route() {
            return this.$route.name
        },
        errorMessage() {
            return this.$store.getters.errorMsg
        },
        account() {
            return this.$store.getters.account.data
        }
    },
    methods: {
        routeToWebsite() {
            window.open(WEBSITE_URL, '_blank');
        },
        logout() {
            this.$store.dispatch(LOGOUT)
        }
    }
}
</script>
<style scoped>
.sidenav {
    padding-right: 0px;
    background-color: #FFFFFF;
}

.sidenav ul {
    list-style: none;
    width: 100%;
    margin-block-start: 0px;
    padding-inline-start: 0px;
}

.sidenav ul .nav-items {
    font-size: 16px;
    font-weight: 700;
    margin-left: 0px;
    padding: 13px 0px 13px 28px;
    text-decoration: unset;
    color: #4A4C56!important;
    letter-spacing: 0.3px;
}

.sidenav ul .nav-items a {
    color: #4A4C56!important;
}

.router-link {
    display: flex;
    align-items: center;
}

.sidenav ul .selected {
    border-left: 6px solid #14243D;
    background-color: #D9E1F0;
    padding-left: 21px;
}

.sidenav ul .nav-items:hover {
    border-left: 6px solid #14243D;
    color: #14243D!important;
    background-color: #D9E1F0;
    cursor: pointer;
    transition: 0.1s;
}

.sidenav ul .nav-items a {
    text-decoration: unset;
}

.sidenav .selected .router-link {
    font-weight: 700;
    color: #14243D!important;
}

.sidenav .links .fa {
    font-size: 17px;
    position: relative;
    color: #808080;
    margin-left: 4px!important;
    margin-right: 12px!important;
}

.sidenav .links .selected .fa {
    color: #14243D !important;
}

.sidenav .footer {
    color: #636365;
    position: absolute;
    padding-left: 33px;
    bottom: 50px;
    font-weight: 700;
    font-size: 14px;
    letter-spacing: 0.3px;
}

.sidenav .footer .signed-in-user {
    font-size: 15px;
}

.sidenav .footer p {
    margin-bottom: 10px;
}

.sidenav .footer p:hover {
    cursor: pointer;
}

.sidenav .footer .fa {
    font-size: 18px;
    position: relative;
    top: 0px;
    margin-right: 8px;
}

.sidenav .info {
    color: #AEAEAE;
    padding-left: 45px;
}

.sidenav .logo-img {
    position: absolute;
    bottom: 25px;
    width: 100%;
}

.sidenav .logo-img img {
    width: 100%;
    margin-top: 15px;
    cursor: pointer;
}

.dashboard-content {
    padding-left: 0px!important;
    margin: 0px!important;
}

.footer .nav-items {
    color: #4A4C56;
}

.footer .selected {
    color: #14243D;
}

@media (max-width: 1000px) {
    .sidenav {
        padding-left: 15px;
    }

    .sidenav .links .fa {
        font-size: 20px;
        padding-top: 5px;
        padding-bottom: 5px;
        width: 50%;
        margin: 0 auto;
    }
}

@media (min-width: 1500px) {
    .sidenav {
        padding-left: 15px;
    }
}

@media (min-width: 1000px) and (max-width: 1500px) {
    .sidenav {
        padding-left: 18px;
    }
}
</style>