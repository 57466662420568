import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import filters from './filters/index'
import titleMixin from './mixin/titleMixin'
import ToastPlugin from 'vue-toast-notification';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import 'vue-toast-notification/dist/theme-bootstrap.css';
import 'primeicons/primeicons.css';
import nprogressPlugin from './plugin/nprogress';

import 'core-js/stable';
import 'regenerator-runtime/runtime';

import VCalendar from 'v-calendar';


const app = createApp(App)
app.mixin(titleMixin)

app.use(store).use(router).use(ToastPlugin).use(VCalendar, {}).use(nprogressPlugin).mount('#app')

app.component('font-awesome-icon', FontAwesomeIcon)

app.config.globalProperties.$filters = filters