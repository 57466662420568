import baseAPI from '@/api/base';
import { EditTicketComment, GetTicketComment, AddTicketComment } from '../dtos/ticket_comment.dto';
import { DELETE_TICKET_COMMENT_URL, TICKET_COMMENT_URL, EDIT_TICKET_COMMENT_URL, ADD_TICKET_COMMENT_URL } from '@/utils/urls';
import { AxiosResponse } from 'axios';

class TicketComment {
    static getTicketComment = async(getTicketComment: GetTicketComment) : Promise<AxiosResponse> => {
        return new Promise(async(resolve, reject) => {
            try {
                let response: AxiosResponse = await baseAPI.get(TICKET_COMMENT_URL, {
                    params: getTicketComment
                })
                console.log('response', response)
                resolve(response)
            } catch (error) {
                reject(error)
            }
        })
    }

    static registerTicketComment = async(registerTicketComment: AddTicketComment) : Promise<AxiosResponse> => {
        return new Promise(async(resolve, reject) => {
            try {
                let response: AxiosResponse = await baseAPI.post(ADD_TICKET_COMMENT_URL, {
                    ...registerTicketComment
                })
                console.log('response', response)
                resolve(response)
            } catch (error) {
                reject(error)
            }
        })
    }

    static editTicketComment = async(editTicketComment: EditTicketComment) : Promise<AxiosResponse> => {
        return new Promise(async(resolve, reject) => {
            try {
                let response: AxiosResponse = await baseAPI.put(EDIT_TICKET_COMMENT_URL, {
                    ...editTicketComment
                })
                console.log('response', response)
                resolve(response)
            } catch (error) {
                reject(error)
            }
        })
    }

    static deleteTicketComment = async(donationId: string) : Promise<AxiosResponse> => {
        return new Promise(async(resolve, reject) => {
            try {
                let response: AxiosResponse = await baseAPI.delete(`${DELETE_TICKET_COMMENT_URL}/${donationId}`)

                resolve(response)
            } catch (error) {
                reject(error)
            }
        })
    }
}

export default TicketComment