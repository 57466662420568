<template>
    <nav class="navbar">
        <a class="navbar-brand"></a>
        <div class="row">
            <button @click="routeToOrganizationDetails()" class="btn btn-badge">
                <span class="profile-pic">
                    <img v-if="account.organization.logo" :src="`${STORAGE_URL}/${account.organization.logo}`" alt="!">
                    <img v-else src="../../public/favicon.png" alt="!">
                </span>
                <span>{{ account.organization.name }}</span>
            </button>
            <div v-if="notifications != null" class="dropdown notification-dropdown">
                <button class=" btn dropdown-toggle" type="button" id="dropdownMenuButton"
                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <span v-if="notifications.length > 0" class="badge">
                        <span v-if="notificationIsLoading" class="spinner-border spinner-border-sm" role="status"
                            aria-hidden="true"></span>
                        <span v-if="!notificationIsLoading">{{ notifications.length }}</span>
                    </span>
                    <img class="notification-bell" src="../assets/icons/bell-icon.svg" alt="">
                </button>
                <div v-if="notifications.length > 0" class="dropdown-menu dropdown-menu-right"
                    aria-labelledby="dropdownMenuButton">
                    <table class="table">
                        <tbody>
                            <template v-bind:key="index" v-for="(notification, index) in notifications">
                                <tr>
                                    <td>{{ notification.purpose.name }}</td>
                                    <td>{{ notification.member.fname}} {{ notification.member.lname }}</td>
                                    <td>{{ this.$filters.convertToHebrewDate(notification.purpose.eventDate) }}</td>
                                    <td><a v-on:click="clearNotification(notification.id)" class="btn btn-link">
                                            <span v-if="notificationIsLoading" class="spinner-border spinner-border-sm"
                                                role="status" aria-hidden="true"></span>
                                            <span v-if="!notificationIsLoading">snooze</span>
                                        </a>
                                    </td>
                                </tr>
                            </template>
                        </tbody>
                    </table>
                    <hr>
                    <div class="d-flex justify-content-center">
                        <a v-on:click="clearNotifications()" class="btn btn-link text-center">
                            <span v-if="notificationIsLoading" class="spinner-border spinner-border-sm" role="status"
                                aria-hidden="true"></span>
                            <span v-if="!notificationIsLoading">Snooze All</span>
                        </a>
                    </div>
                </div>
            </div>
            <!-- <img class="notification-bell" src="../assets/icons/bell-icon.svg" alt=""> -->
            <div class="dropdown">
                <editUserModal v-if="showEditUserModal" :selectedUser="selectedUser" :type="EDIT_ACCOUNT_TYPE_SELF"
                    @close-edit-user-modal="showEditUserModal = false" @refresh-users="getUsers()" />
                <button v-if="account != null" class=" btn dropdown-toggle" type="button" id="dropdownMenuButton"
                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <span class="name">{{ account.fname }} {{ account.lname }}</span><br>
                    <span class="role">{{ this.$filters.capitalize(account.role) }}</span>
                </button>
                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                    <a v-on:click="selectUser(user)" class="dropdown-item">Update</a>
                    <a v-on:click="logout()" class="dropdown-item">Logout</a>
                </div>
            </div>
        </div>
    </nav>
</template>
<script>
import editUserModal from '@/components/modals/User/Edit_User_Modal.vue'
import { CLEAR_NOTIFICATIONS, GET_NOTIFICATIONS, LOGOUT } from '@/store/action_types';
import { ORGANIZATION_DETAILS_ROUTE } from '@/router/routes'
import { EDIT_ACCOUNT_TYPE_SELF } from '@/utils/const';
import { STORAGE_URL } from '@/utils/const';

export default {
    components: {
        editUserModal
    },
    data() {
        return {
            EDIT_ACCOUNT_TYPE_SELF,
            STORAGE_URL,
            ORGANIZATION_DETAILS_ROUTE,
            currentTime: '',
            showEditUserModal: false
        };
    },
    computed: {
        account() {
            return this.$store.getters.account.data
        },
        notificationIsLoading() {
            return this.$store.getters.notification.isLoading
        },
        notification() {
            return this.$store.getters.notification.data
        },
        notificationsIsLoading() {
            return this.$store.getters.notifications.isLoading
        },
        notifications() {
            return this.$store.getters.notifications.data
        }
    },
    methods: {
        updateTime() {
            const now = new Date();
            const options = {
                year: 'numeric',
                month: 'short',
                day: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
                hour12: true
            };
            this.currentTime = now.toLocaleString('en-US', options);
        },
        selectUser(user) {
            this.showEditUserModal = true
            this.selectedUser = this.account
        },
        routeToOrganizationDetails() {
            this.$router.push({ name: ORGANIZATION_DETAILS_ROUTE, params: { id: this.account.org } })
        },
        clearNotification(notificationId) {
            this.$store.dispatch(CLEAR_NOTIFICATIONS, { notificationIds: [ notificationId ] }).then((res) => {
                if (res) {
                    this.getNotifications()
                }
            })
        },
        clearNotifications() {
            this.$store.dispatch(CLEAR_NOTIFICATIONS, { notificationIds: this.notifications.map((e) => e.id) }).then((res) => {
                if (res) {
                    this.getNotifications()
                }
            })
        },
        getNotifications() {
            this.$store.dispatch(GET_NOTIFICATIONS, { org: this.account.org, isSeen: false })
        },
        logout() {
            this.$store.dispatch(LOGOUT).then((res) => {
                this.$router.go()
            })
        }
    },
    mounted() {
        this.updateTime();
        this.timer = setInterval(this.updateTime, 10000);
        this.getNotifications()
    },
    beforeDestroy() {
        clearInterval(this.timer);
    }
}
</script>
<style scoped>
.navbar {
    padding-bottom: 50px;
}

.navbar-brand {
    padding-top: 8.5px;
    padding-bottom: 8.5px;
}

.navbar .btn-badge:active {
    background-color: #46556e;
}

.navbar .btn-badge {
    color: #FFFFFF;
    background-color: #14243D;
    height: 45px;
    margin-top: 7px;
    border-radius: 8px;
    padding: 10px, 25px, 10px, 25px !important;
    margin-right: 15px;
}

.navbar .btn-badge span {
    font-size: 14px;
    font-weight: 300!important;
    letter-spacing: 0.4px;
}

.navbar .btn-badge .profile-pic img {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    object-fit: cover;
    border: 1px solid transparent;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-right: 10px;
}

.navbar .notification-bell {
    margin-right: 7px;
    cursor: pointer;
}

.navbar .dropdown {
    margin-right: 60px;
}

.navbar .dropdown .dropdown-toggle .name {
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 0.5px;
    color: #1D1F2C;
}

.navbar .dropdown .dropdown-toggle .role {
    font-size: 13px;
    float: left;
    color: #4A4C56;
}

.navbar .dropdown .dropdown-menu {
    border: 1px solid transparent;
}

.navbar .dropdown .dropdown-toggle::after {
    color: #858D9D;
    position: absolute;
    right: -25px;
    font-size: 20px;
}

.navbar .dropdown .dropdown-item:active {
    background-color: #FFFFFF;
}

.navbar .dropdown .dropdown-item {
    font-size: 15px;
    cursor: pointer;
}

.navbar .notification-dropdown {
    margin: 0px;
    margin-top: 6px
}

.navbar .notification-dropdown .dropdown-menu {
    width: 500px;
    font-size: 12px;
    padding: 0px;
    border-radius: 10px;
    border: 1px solid #EEF0F5
}

.navbar .notification-dropdown .dropdown-menu table {
    margin: 0px;
    border-radius: 10px;
}

.navbar .notification-dropdown .dropdown-menu .btn {
    font-size: 13px;
    font-weight: 600;
    padding: 5px;
    align-self: center;
    color: #6A8CC6;
}

.navbar .notification-dropdown .dropdown-menu hr {
    margin-top: 0px;
    margin-bottom: 5px;
}


.navbar .notification-dropdown .dropdown-menu table .btn {
    font-size: 13px;
    padding: 0px;
    margin: 0px;
    color: #6A8CC6;
}

.navbar .notification-dropdown .dropdown-toggle img {
    margin-top: 9px;
    height: 20px;
}

.navbar .notification-dropdown .dropdown-toggle .badge {
    position: absolute;
    top: 5px;
    left: 23px;
    color: #FFFFFF;
    font-size: 12px;
    padding: 4px, 8px, 4px, 8px;
    background-color: #E5D3AD;
}

/*.navbar .notification-dropdown .dropdown-menu table,
.navbar .notification-dropdown .dropdown-menu table th,
.navbar .notification-dropdown .dropdown-menu table td {
    border: none;
}*/

.navbar .notification-dropdown .dropdown-toggle::after {
    display: none;
}
</style>