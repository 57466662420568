<template>
    <div>
        <vue-final-modal :drag="false" drag-selector="" v-model="showModal" content-class="modal-content"
            classes="modal-container" focus-trap>
            <button class="modal__close btn btn-light" @click="viewAlert()">
                <i class="pi pi-times"></i>
            </button>
            <span class="modal__title"><font-awesome-icon icon="fa-solid fa-circle-exclamation" class="fa" />
                Alert</span>
            <br>
            <div class="modal__content">
                <p v-if="errorMessage" class="text-center text-danger auth-status-msg">{{ errorMessage }}</p>
                <div class="row">
                    <div v-if="alertNotification != null" class="col-12">
                        <h6 v-if="alertNotification.alert">{{ alertNotification.alert.message }}</h6>
                    </div>
                </div>
                <br>
                <!-- <div class="row">
                    <div class="col-lg-2 offset-lg-6">
                        <button v-on:click="deleteUser()" class="btn text-danger"
                            :disabled="userIsLoading ? true : false">
                            <span v-if="userIsLoading" class="spinner-border spinner-border-sm" role="status"
                                aria-hidden="true"></span>
                            <span v-if="!userIsLoading">Confirm</span>
                        </button>
                    </div>
                    <div class="col-lg-2 offset-lg-1">
                        <button v-on:click="showModal = false" class="btn">
                            Cancel
                        </button>
                    </div>
                </div> -->
            </div>
        </vue-final-modal>
    </div>
</template>
<script>
import { DELETE_USER, GET_ALERT, GET_ALERT_NOTIFICATION, GET_TEMPLATES, VIEW_ALERT_NOTIFICATION } from '@/store/action_types'
import { $vfm, VueFinalModal, ModalsContainer } from 'vue-final-modal'

import { Field, Form, ErrorMessage } from 'vee-validate';
import { defineRule } from 'vee-validate';
import { required, email, min } from '@vee-validate/rules';

import { library } from '@fortawesome/fontawesome-svg-core'
import { faCircleExclamation } from '@fortawesome/free-solid-svg-icons'
library.add(faCircleExclamation)

defineRule('required', required);
defineRule('email', email);
defineRule('min', min);

export default {
    props: {
        selectedUserId: String
    },
    components: {
        VueFinalModal,
        ModalsContainer,
        Form,
        Field,
        ErrorMessage
    },
    data() {
        return {
            timer: null,
            showModal: false
        }
    },
    computed: {
        errorMessage() {
            return this.$store.getters.errorMsg
        },
        alertNotification() {
            return this.$store.getters.alertNotification.data
        },
        alertNotificationIsLoading() {
            return this.$store.getters.alertNotification.isLoading
        }
    },
    methods: {
        viewAlert() {
            if (this.alertNotification) {
                if (!this.alertNotification.isSeen) {
                    this.$store.dispatch(VIEW_ALERT_NOTIFICATION, { id: this.alertNotification.id }).then((res) => {
                        // if (res) {
                        this.showModal = false
                        // }
                    })
                }
            }
        },
        getAlert() {
            this.$store.dispatch(GET_ALERT_NOTIFICATION, this.selectedUserId).then((res) => {
                if (res) {
                    this.showModal = true
                }
            })
        }
    },
    mounted() {
        this.getAlert()
        this.timer = setTimeout(() => {
            this.viewAlert();
        }, 20000);
    },
    beforeDestroy() {
        if (this.timer) {
            clearTimeout(this.timer);
        }
    },
}
</script>
<style scoped>
::v-deep .modal-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 25%;
    position: relative;
    left: 74.5%;
    margin-top: 41%;
}

::v-deep .modal-content {
    display: flex;
    flex-direction: column;
    margin: 0 1rem;
    padding: 1rem;
    border: 1px solid #e2e8f0;
    border-radius: 0.2rem;
    background: #fff;
}

.modal__title {
    font-size: 18px;
    font-weight: 700;
}

.modal__close {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
}

.modal__content .nav-tabs .nav-link {
    font-size: 15px !important;
}

.btn-close {
    border-radius: 10px;
    font-size: 13px;
    font-weight: 600;
    width: 75%;
    background-color: #CAB781;

}

.appointment-card {
    margin-top: 5px;
}

.appointment-card .card-body {
    padding: 0px 5px 0px 5px;
}

.appointment-card .customer {
    font-size: 15px;
}

.appointment-card .badge {
    margin-left: 10px;
    margin-right: 5px;
}

label {
    font-size: 13px;
}

input {
    height: 35px !important;
    font-size: 13px;
}

input::placeholder {
    font-size: 13px;
}

select {
    height: 35px !important;
    font-size: 13px;
}

select::placeholder {
    font-size: 13px;
}

.form-check {
    position: relative;
    display: block;
    margin-top: 25px;
    margin-bottom: 0.75rem;
    padding-left: 0px;
}

.form-check-toggle {
    position: relative;
    padding-left: 0;
    line-height: 30px;
}

.form-check-toggle input {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 0%;
    height: 0%;
    margin: 0;
    cursor: pointer;
    opacity: 0;
    filter: alpha(opacity=0);
}

.form-check-toggle input+span {
    cursor: pointer;
    user-select: none;
    height: 30px;
    margin-left: 70px;
    display: block;
}

.form-check-toggle input+span:before {
    content: "";
    position: absolute;
    left: 0;
    display: inline-block;
    height: 30px;
    width: 50px;
    background: #F0F5FC;
    border: solid 1px #D4D3D3;
    transition: background 0.3s ease-in-out, border-color 0.3s ease-in-out;
    border-radius: 7px;
}

.form-check-toggle input+span:after {
    width: 28px;
    height: 28px;
    margin-top: 1px;
    margin-left: 1px;
    border-radius: 50%;
    position: absolute;
    left: 0;
    top: 0;
    display: block;
    background: #fff;
    transition: margin-left 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
    text-align: center;
    font-weight: bold;
    content: "";
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.2), 0 0 5px rgba(0, 0, 0, 0.05);
}

.form-check-toggle input:checked+span:after {
    content: "";
    margin-left: 21px;
    box-shadow: none;
}

.form-check-toggle input:checked+span:before {
    background-color: #CAB781;
    border-color: #CAB781;
    transition: background 0.3s ease-in-out, border-color 0.3s ease-in-out;
}

button {
    font-size: 15px;
}
</style>