import { createStore } from 'vuex'
import { LOCAL_STORAGE_KEY } from '@/utils/const';
import createVuexPersistedState from 'vuex-persistedstate';
import account from './modules/auth'
import alert from './modules/alert'
import donation from './modules/donation'
import error from './modules/error'
import users from './modules/user';
import screen from './modules/screen';
import stats from './modules/stats';
import template from './modules/template';
import organization from './modules/organization';
import announcement from './modules/announcement';
import member from './modules/member';
import purpose from './modules/purpose';
import notification from './modules/notification';
import minyanim from './modules/minyanim';
import room from './modules/room';
import ticket from './modules/ticket';
import ticketComment from './modules/ticket_comment';
import minyanimType from './modules/minyanim_type';
import alertNotification from './modules/alert_notification';

export default createStore({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    account,
    announcement,
    alert,
    alertNotification,
    error,
    donation,
    member,
    minyanim,
    minyanimType,
    notification,
    organization,
    purpose,
    room,
    screen,
    stats,
    template,
    ticket,
    ticketComment,
    users
  },
  plugins: [
    createVuexPersistedState({
        key: LOCAL_STORAGE_KEY,
        storage: window.localStorage
    })
  ]
})
