import axios, { AxiosResponse } from 'axios'
import { SET_ANNOUNCEMENTS, SET_ANNOUNCEMENT, SET_ERRORMSG } from '../mutation_types'
import {
    CLEAR_ANNOUNCEMENT_ISLOADING,
    DELETE_ANNOUNCEMENT,
    EDIT_ANNOUNCEMENT,
    GET_ANNOUNCEMENTS,
    ADD_ANNOUNCEMENT,
} from '../action_types'
import { StateObject } from '@/dtos/state'
import Announcement from '@/services/announcement'
import { Module } from 'vuex'
import { EditAnnouncement, GetAnnouncement, RegisterAnnouncement } from '@/dtos/announcement.dto'

const announcement: Module<any, {}> = {
    state: {
        announcement: {
            data: null,
            isLoading: false
        },
        announcements: {
            data: null,
            isLoading: false
        }
    },
    getters: {
        announcement: (state: { announcement: any }) => state.announcement,
        announcements: (state: { announcements: any }) => state.announcements
    },
    mutations: {
        [SET_ANNOUNCEMENT]: (state: { announcement: StateObject }, payload: { data: null; status: any; isLoading: any }) => { payload.data != null ? state.announcement.data = payload.data : null, state.announcement.isLoading = payload.isLoading },
        [SET_ANNOUNCEMENTS]: (state: { announcements: StateObject }, payload: { data: null; status: any; isLoading: any }) => { payload.data != null ? state.announcements.data = payload.data : null, state.announcements.isLoading = payload.isLoading }
    },
    actions: {
        [ADD_ANNOUNCEMENT]: async({ commit }: any, payload: any) => {
            commit(SET_ANNOUNCEMENT, { data: null, isLoading: true })

            let result: AxiosResponse = await Announcement.registerAnnouncement(payload as RegisterAnnouncement)
          
            if(result) {
                if (result.data.status) {
                    commit(SET_ANNOUNCEMENT, { data: result.data.data, isLoading: false })
                    return true;
                }
                commit(SET_ANNOUNCEMENT, { data: null, isLoading: false })
                commit(SET_ERRORMSG, result.data.msg)
                return false;
            } else {
                commit(SET_ANNOUNCEMENT, { isLoading: false })
                return false;
            }
        },
        [GET_ANNOUNCEMENTS]: async({ commit }: any, payload: any) => {
            commit(SET_ANNOUNCEMENTS, { data: null, isLoading: true })

            let result: AxiosResponse = await Announcement.getAnnouncements(payload as GetAnnouncement)
          
            if(result) {
                if (result.data.status) {
                    console.log('result.data.data', result.data.data)
                    commit(SET_ANNOUNCEMENTS, { data: result.data.data, isLoading: false })
                    return true;
                }
                commit(SET_ANNOUNCEMENTS, { data: null, isLoading: false })
                commit(SET_ERRORMSG, result.data.msg)
                return false;
            } else {
                commit(SET_ANNOUNCEMENTS, { isLoading: false })
                return false;
            }
        },
        [EDIT_ANNOUNCEMENT]: async({ commit }: any, payload: any) => {
            commit(SET_ANNOUNCEMENT, { data: null, isLoading: true })

            let result: AxiosResponse = await Announcement.editAnnouncement(payload as EditAnnouncement)
          
            if(result) {
                if (result.data.status) {
                    console.log('result.data.data', result.data.data)
                    commit(SET_ANNOUNCEMENT, { data: result.data.data, isLoading: false })
                    return true;
                }
                commit(SET_ANNOUNCEMENT, { data: null, isLoading: false })
                commit(SET_ERRORMSG, result.data.msg)
                return false;
            } else {
                commit(SET_ANNOUNCEMENT, { isLoading: false })
                return false;
            }
        },
        [DELETE_ANNOUNCEMENT]: async({ commit }: any, payload: string) => {
            commit(SET_ANNOUNCEMENT, { data: null, isLoading: true })

            let result: AxiosResponse = await Announcement.deleteAnnouncement(payload)
          
            if(result) {
                if (result.data.status) {
                    console.log('result.data.data', result.data.data)
                    commit(SET_ANNOUNCEMENT, { data: result.data.data, isLoading: false })
                    return true;
                }
                commit(SET_ANNOUNCEMENT, { data: null, isLoading: false })
                commit(SET_ERRORMSG, result.data.msg)
                return false;
            } else {
                commit(SET_ANNOUNCEMENT, { isLoading: false })
                return false;
            }
        },
        [CLEAR_ANNOUNCEMENT_ISLOADING]: ({ commit }) => {
            commit(SET_ANNOUNCEMENT, { isLoading: false })
            commit(SET_ANNOUNCEMENTS, { isLoading: false })
        }
    }
}

export default announcement