export const LARGE_SCREEN_SIZE = 992
export const AXIOS_TIMEOUT = 30000

export const SERVER_ERR_MSG = 'Server Error!'

export const DEFAULT_ERROR_MSG = 'Error! Something went wrong.'
export const TOAST_GLOBAL_CONFIG = { position: 'top-right', dismissible: true }

export const LOCAL_STORAGE_KEY = 'local'

export const DATE_OPTIONS : Intl.DateTimeFormatOptions = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' };
export const TIME_OPTIONS : Intl.DateTimeFormatOptions = { hour: 'numeric', minute: 'numeric' };
export const DATETIME_OPTIONS : Intl.DateTimeFormatOptions = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric' };

export const DONATION_TYPE_DAILY = 'daily'
export const DONATION_TYPE_WEEKLY = 'weekly'
export const DONATION_TYPE_MONTHLY = 'monthly'
export const DONATION_TYPE_YEARLY = 'yearly'

export const DEFAULT_LAST_X_DAYS_USER_STATS_FETCH: number = 10

export const DONTATION_TYPE = [
    'daily',
    'weekly',
    'monthly',
    'yearly'
]

export const HEBREW_MONTHS = [
    "Nisan",
    "Iyar",
    "Sivan",
    "Tammuz",
    "Av",
    "Elul",
    "Tishrei",
    "Cheshvan",
    "Kislev",
    "Tevet",
    "Shevat",
    "Adar",
    "Adar I",
    "Adar II"
]

export const DONATION_TITLES = [
    'נתנדב על ידי ידידינו הנכבד, רודף צדקה וחסד, שמו מפארים',
    'נתנדב על ידי ידידינו הרבני הנגיד, מפורסם לשם ולתהלה, בתוך עם סגולה, כש”ת',
    'נתנדב על ידי ידידינו הרה”ג המפואר, צנמ”ס, כבוד שמו תפארתו'
]

export const WEBSITE_URL = 'https://persim.us/'
export const API_URL = 'https://api.persim.us'
// export const API_URL = 'http://localhost:8080'

export const DONATION_VIEW_LIST = 'DONATION_VIEW_LIST'
export const DONATION_VIEW_CALENDAR = 'DONATION_VIEW_CALENDAR'

export const MINYANIM_VIEW_ROOMS = 'MINYANIM_VIEW_ROOMS'
export const MINYANIM_VIEW_SCHEDULES = 'MINYANIM_VIEW_SCHEDULES'

export const EDIT_ACCOUNT_TYPE_USER = 'EDIT_ACCOUNT_TYPE_USER'
export const EDIT_ACCOUNT_TYPE_SELF = 'EDIT_ACCOUNT_TYPE_SELF'

export const USER_ROLES = [
    'admin',
    'user'
]

export const USER_ROLE_ADMIN = 'admin'
export const USER_ROLE_USER = 'user'

export const STORAGE_URL = 'https://persim-screens.nyc3.digitaloceanspaces.com'

export const VUE_PROGRESSBAR_OPTIONS = {
    color: '#bffaf3',
    failedColor: '#874b4b',
    thickness: '5px',
    transition: {
    speed: '0.2s',
    opacity: '0.6s',
    termination: 300
    },
    autoRevert: true,
    location: 'left',
    inverse: false
}

export const DISPLAY_REFRESH_DONATION_SOCKET_EVENT = 'DISPLAY_REFRESH_DONATION_SOCKET_EVENT'

export const DISPLAY_SCREEN_ONE_ID = '9717ca74-f5ac-4cc5-bd66-1d8d5b60d41e'
export const DISPLAY_SCREEN_TWO_ID = '1f3d1081-d159-4c82-b4cb-9d63dde22def'
export const DISPLAY_SCREEN_THREE_ID = 'c8df9f96-41c1-4324-8bf0-fc146f204b7e'

export const DATE_PICKER_DONATION_DATE = 'DATE_PICKER_DONATION_DATE'
export const DATE_PICKER_DONATION_DISPLAY_START_DATE = 'DATE_PICKER_DONATION_DISPLAY_START_DATE'
export const DATE_PICKER_DONATION_DISPLAY_END_DATE = 'DATE_PICKER_DONATION_DISPLAY_END_DATE'

export const DATE_PICKER_ANNOUNCEMENT_DISPLAY_START_DATE = 'DATE_PICKER_ANNOUNCEMENT_DISPLAY_START_DATE'
export const DATE_PICKER_ANNOUNCEMENT_DISPLAY_END_DATE = 'DATE_PICKER_ANNOUNCEMENT_DISPLAY_END_DATE'

export const DATE_PICKER_PURPOSE_DATE = 'DATE_PICKER_PURPOSE_DATE'
export const DATE_PICKER_PURPOSE_CUSTOM_DATE = 'DATE_PICKER_PURPOSE_CUSTOM_DATE'

export const PURPOSE_REMINDER_DATES = [
    'a month before',
    'a week before',
    '3 days before',
    'a day before',
    'add custom date'
]

export const MINYANIM_TYPES = [
    'Shacharis',
    'Mincha',
    'Maariv'
]

export const MINYANIM_DATE_TYPES = [
    'Sunday',
    'Monday - Thursday',
    'Friday',
    'Shabbas',
    'Custom Date'
]

export const DATE_PICKER_MINYANIM_START_DATE = 'DATE_PICKER_MINYANIM_START_DATE'
export const DATE_PICKER_MINYANIM_END_DATE = 'DATE_PICKER_MINYANIM_END_DATE'

export const MINYANIM_TYPE_SHACHARIS = 'Shacharis'
export const MINYANIM_TYPE_MINCHA = 'Mincha'
export const MINYANIM_TYPE_MAARIV = 'Maariv'

export const TICKET_STATUS_OPEN = 'open'
export const TICKET_STATUS_CLOSED = 'closed'


export const TICKET_COMMENT_TYPES = [
    'ticket-close',
    'ticket-reopen',
    'ticket-comment'
]
