import baseAPI from '@/api/base';
import { EditMinyanim, AddMinyanim, GetMinyanim } from '../dtos/minyanim.dto';
import { DELETE_MINYANIM_URL, DISPLAY_MINYANIM_URL, MINYANIM_URL, EDIT_MINYANIM_URL, ADD_MINYANIM_URL, TOGGLE_MINYANIM_IS_ACTIVE_URL } from '@/utils/urls';
import { AxiosResponse } from 'axios';

class Minyanim {
    static getMinyanims = async(getMinyanim: GetMinyanim) : Promise<AxiosResponse> => {
        return new Promise(async(resolve, reject) => {
            try {
                let response: AxiosResponse = await baseAPI.get(MINYANIM_URL, {
                    params: getMinyanim
                })
                console.log('response', response)
                resolve(response)
            } catch (error) {
                reject(error)
            }
        })
    }

    static getDisplayMinyanims = async(getMinyanim: GetMinyanim) : Promise<AxiosResponse> => {
        return new Promise(async(resolve, reject) => {
            try {
                let response: AxiosResponse = await baseAPI.get(DISPLAY_MINYANIM_URL, {
                    params: getMinyanim
                })
                console.log('response', response)
                resolve(response)
            } catch (error) {
                reject(error)
            }
        })
    }

    static registerMinyanim = async(addMinyanim: AddMinyanim) : Promise<AxiosResponse> => {
        return new Promise(async(resolve, reject) => {
            try {
                let response: AxiosResponse = await baseAPI.post(ADD_MINYANIM_URL, {
                    ...addMinyanim
                })
                console.log('response', response)
                resolve(response)
            } catch (error) {
                reject(error)
            }
        })
    }

    static editMinyanim = async(editMinyanim: EditMinyanim) : Promise<AxiosResponse> => {
        return new Promise(async(resolve, reject) => {
            try {
                let response: AxiosResponse = await baseAPI.put(EDIT_MINYANIM_URL, {
                    ...editMinyanim
                })
                console.log('response', response)
                resolve(response)
            } catch (error) {
                reject(error)
            }
        })
    }

    static toggleMinyanimIsActive = async(editMinyanim: EditMinyanim) : Promise<AxiosResponse> => {
        return new Promise(async(resolve, reject) => {
            try {
                let response: AxiosResponse = await baseAPI.put(TOGGLE_MINYANIM_IS_ACTIVE_URL, {
                    ...editMinyanim
                })
                console.log('response', response)
                resolve(response)
            } catch (error) {
                reject(error)
            }
        })
    }

    static deleteMinyanim = async(minyanimId: string) : Promise<AxiosResponse> => {
        return new Promise(async(resolve, reject) => {
            try {
                let response: AxiosResponse = await baseAPI.delete(`${DELETE_MINYANIM_URL}/${minyanimId}`)

                resolve(response)
            } catch (error) {
                reject(error)
            }
        })
    }
}

export default Minyanim