// account
export const SET_ACCOUNT = 'setAccount';
export const SET_SESSION_TOKEN = 'setToken';

// users
export const SET_USER = 'setUser';
export const SET_USERS = 'setUsers';

// donation
export const SET_DONATION = 'setDonation';
export const SET_DONATIONS = 'setDonations';
export const SET_DISPLAY_DONATIONS = 'setDisplayDonations';
export const SET_CALENDAR_DONATIONS = 'setCalendarDonations';

// template
export const SET_TEMPLATE = 'setTemplate';
export const SET_TEMPLATES = 'setTemplates';

// announcement
export const SET_ANNOUNCEMENT = 'setAnnouncement';
export const SET_ANNOUNCEMENTS = 'setAnnouncements';

// screen
export const SET_SCREEN = 'setScreen';
export const SET_SCREENS = 'setScreens';
export const SET_REFRESH_SCREEN = 'setRefreshScreen';

// organization
export const SET_ORGANIZATION = 'setOrganization';
export const SET_ORGANIZATIONS = 'setOrganizations';

// member
export const SET_MEMBER = 'setMember';
export const SET_MEMBERS = 'setMembers';

// purpose
export const SET_PURPOSE = 'setPurpose';
export const SET_PURPOSES = 'setPurposes';

// notifications
export const SET_NOTIFICATION = 'setNotification';
export const SET_NOTIFICATIONS = 'setNotifications';
export const SET_ALL_NOTIFICATIONS = 'setAllNotifications';

// minyanim
export const SET_MINYANIM = 'setMinyanim';
export const SET_MINYANIMS = 'setMinyanims';
export const SET_DISPLAY_MINYANIMS = 'setDisplayMinyanims';

// room
export const SET_ROOM = 'setRoom';
export const SET_ROOMS = 'setRooms';

// alert
export const SET_ALERT = 'setAlert';
export const SET_ALERTS = 'setAlerts';

// alert_notification
export const SET_ALERT_NOTIFICATION = 'setAlertNotification';
export const SET_ALERT_NOTIFICATIONS = 'setAlertNotifications';

// ticket
export const SET_TICKET = 'setTicket';
export const SET_TICKETS = 'setTickets';

// ticket_comment
export const SET_TICKET_COMMENT = 'setTicketComment';
export const SET_TICKET_COMMENTS = 'setTicketComments';

// minyanim_type
export const SET_MINYANIM_TYPE = 'setMinyanimType';
export const SET_MINYANIM_TYPES = 'setMinyanimTypes';

// stats
export const SET_DASHBOARD_STATS = 'setDashboardStats';

// error
export const SET_ERRORMSG = 'setErrorMsg';