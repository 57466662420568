<template>
  <div id="app">
    <router-view />
    <alertModal/>
  </div>
</template>
<script>
import alertModal from '@/components/Alert_Modal.vue'

export default {
  components: {
    alertModal
  },
  watch: {
    // isLoading(newValue) {
    //   if (newValue) {
    //     this.$nprogress.start();
    //   } else {
    //     this.$nprogress.done();
    //   }
    // }
  },
  computed: {
    isLoading() {
      // return this.$store.getters.account.isLoading ||
      //   this.$store.getters.announcement.isLoading ||
      //   this.$store.getters.announcements.isLoading ||
      //   this.$store.getters.donation.isLoading ||
      //   this.$store.getters.donations.isLoading ||
      //   this.$store.getters.calendarDonations.isLoading ||
      //   this.$store.getters.member.isLoading ||
      //   this.$store.getters.members.isLoading ||
      //   this.$store.getters.organization.isLoading ||
      //   this.$store.getters.organizations.isLoading ||
      //   this.$store.getters.purpose.isLoading ||
      //   this.$store.getters.purposes.isLoading ||
      //   this.$store.getters.screen.isLoading ||
      //   this.$store.getters.screens.isLoading ||
      //   this.$store.getters.ticket.isLoading ||
      //   this.$store.getters.tickets.isLoading ||
      //   this.$store.getters.ticketComment.isLoading ||
      //   this.$store.getters.ticketComments.isLoading ||
      //   this.$store.getters.dashboardStats.isLoading ||
      //   this.$store.getters.template.isLoading ||
      //   this.$store.getters.templates.isLoading ||
      //   this.$store.getters.user.isLoading ||
      //   this.$store.getters.users.isLoading
      // return false
    }
  },
  // mounted() {
  //   this.$nprogress.start();
  // }
}

</script>
<style lang="css">
@import './assets/style/main.css';
@import '~nprogress/nprogress.css';

</style>
