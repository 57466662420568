// account
export const LOGIN = 'login'
export const SIGNUP = 'signup'
export const LOGOUT = 'logout'
export const UPDATE_ACCOUNT = 'updateAccount'
export const GET_AUTH_ACCOUNT = 'getAuthAccount'
export const CLEAR_ACCOUNT_ISLOADING = 'clearAccountIsLoading'

// donation
export const REGISTER_DONATION = 'registerDonation'
export const GET_DONATIONS = 'getDonations'
export const GET_DISPLAY_DONATIONS = 'getDisplayDonations'
export const GET_CALENDAR_DONATIONS = 'getCalendarDonations'
export const DELETE_DONATION = 'deleteDonation'
export const EDIT_DONATION = 'editDonation'
export const TOGGLE_DONATION_IS_ACTIVE = 'toggleDonationIsActive'
export const CLEAR_DISPLAY_DONATIONS = 'clearDisplayDonation'
export const CLEAR_DONATION_ISLOADING = 'clearDonationIsLoading'

// template
export const ADD_TEMPLATE = 'registerTemplate'
export const GET_TEMPLATES = 'getTemplates'
export const GET_CALENDAR_TEMPLATES = 'getCalendarTemplates'
export const DELETE_TEMPLATE = 'deleteTemplate'
export const EDIT_TEMPLATE = 'editTemplate'
export const CLEAR_TEMPLATE_ISLOADING = 'clearTemplateIsLoading'

// announcement
export const ADD_ANNOUNCEMENT = 'registerAnnouncement'
export const GET_ANNOUNCEMENTS = 'getAnnouncements'
export const GET_CALENDAR_ANNOUNCEMENTS = 'getCalendarAnnouncements'
export const DELETE_ANNOUNCEMENT = 'deleteAnnouncement'
export const EDIT_ANNOUNCEMENT = 'editAnnouncement'
export const CLEAR_ANNOUNCEMENT_ISLOADING = 'clearAnnouncementIsLoading'

// screen
export const ADD_SCREEN = 'registerScreen'
export const GET_SCREEN = 'getScreen'
export const GET_SCREENS = 'getScreens'
export const GET_CALENDAR_SCREENS = 'getCalendarScreens'
export const EDIT_SCREEN = 'editScreen'
export const REFRESH_SCREEN = 'refreshScreen'
export const DELETE_SCREEN = 'deleteScreen'
export const CLEAR_SCREEN_ISLOADING = 'clearScreenIsLoading'

// organization
export const REGISTER_ORGANIZATION = 'registerOrganization'
export const GET_ORGANIZATION = 'getOrganization'
export const GET_ORGANIZATIONS = 'getOrganizations'
export const GET_CALENDAR_ORGANIZATIONS = 'getCalendarOrganizations'
export const DELETE_ORGANIZATION = 'deleteOrganization'
export const EDIT_ORGANIZATION = 'editOrganization'
export const TOGGLE_ORGANIZATION_IS_ACTIVE = 'toggleOrganizationIsActive'
export const CLEAR_ORGANIZATION_ISLOADING = 'clearOrganizationIsLoading'

// member
export const ADD_MEMBER = 'registerMember'
export const GET_MEMBERS = 'getMembers'
export const GET_CALENDAR_MEMBERS = 'getCalendarMembers'
export const DELETE_MEMBER = 'deleteMember'
export const EDIT_MEMBER = 'editMember'
export const CLEAR_MEMBERS_ISLOADING = 'clearMembersIsLoading'

// purpose
export const ADD_PURPOSE = 'registerPurpose'
export const GET_PURPOSES = 'getPurposes'
export const GET_CALENDAR_PURPOSES = 'getCalendarPurposes'
export const EDIT_PURPOSE = 'editPurpose'
export const DELETE_PURPOSE = 'deletePurpose'
export const CLEAR_PURPOSE_ISLOADING = 'clearPurposeIsLoading'

// notification
export const GET_NOTIFICATIONS = 'getNotifications'
export const GET_ALL_NOTIFICATIONS = 'getAllNotifications'
export const CLEAR_NOTIFICATIONS = 'cleatNotifications'
export const CLEAR_NOTIFICATIONS_ISLOADING = 'clearNotificationIsLoading'

// users
export const GET_USERS = 'getUsers'
export const REGISTER_USER = 'registerUser'
export const EDIT_USER = 'editUser'
export const TOGGLE_USER_IS_ACTIVE = 'toggleUserIsActive'
export const DELETE_USER = 'deleteUser'
export const CLEAR_USERS_ISLOADING = 'clearUsersIsLoading'

// minyanim
export const ADD_MINYANIM = 'registerMinyanim'
export const GET_MINYANIMS = 'getMinyanims'
export const GET_DISPLAY_MINYANIMS = 'getDisplayMinyanims'
export const DELETE_MINYANIM = 'deleteMinyanim'
export const TOGGLE_MINYANIM_IS_ACTIVE = 'toggleMinyanimIsActive'
export const EDIT_MINYANIM = 'editMinyanim'
export const CLEAR_MINYANIMS_ISLOADING = 'clearMinyanimsIsLoading'

// room
export const REGISTER_ROOM = 'registerRoom'
export const GET_ROOMS = 'getRooms'
export const DELETE_ROOM = 'deleteRoom'
export const EDIT_ROOM = 'editRoom'
export const CLEAR_ROOMS_ISLOADING = 'clearRoomsIsLoading'

// alert
export const ADD_ALERT = 'addAlert'
export const GET_ALERTS = 'getAlerts'
export const GET_ALERT = 'getAlert'
export const DELETE_ALERT = 'deleteAlert'
export const EDIT_ALERT = 'editAlert'
export const CLEAR_ALERTS_ISLOADING = 'clearAlertsIsLoading'

// alert_notification
export const GET_ALERT_NOTIFICATIONS = 'getAlertNotifications'
export const GET_ALERT_NOTIFICATION = 'getAlertNotification'
export const VIEW_ALERT_NOTIFICATION = 'editAlertNotification'
export const CLEAR_ALERT_NOTIFICATIONS_ISLOADING = 'clearAlertNotificationsIsLoading'

// ticket
export const ADD_TICKET = 'addTicket'
export const GET_TICKETS = 'getTickets'
export const DELETE_TICKET = 'deleteTicket'
export const EDIT_TICKET = 'editTicket'
export const CLOSE_TICKET = 'closeTicket'
export const CLEAR_TICKETS_ISLOADING = 'clearTicketsIsLoading'

// ticket
export const ADD_TICKET_COMMENT = 'addTicketComment'
export const GET_TICKET_COMMENTS = 'getTicketComments'
export const DELETE_TICKET_COMMENT = 'deleteTicketComment'
export const EDIT_TICKET_COMMENT = 'editTicketComment'
export const CLEAR_TICKET_COMMENTS = 'clearTicketComments'
export const CLEAR_TICKET_COMMENTS_ISLOADING = 'clearTicketCommentsIsLoading'

// minyanim type
export const ADD_MINYANIM_TYPE = 'addMinyanimType'
export const GET_MINYANIM_TYPES = 'getMinyanimTypes'
export const DELETE_MINYANIM_TYPE = 'deleteMinyanimType'
export const EDIT_MINYANIM_TYPE = 'editMinyanimType'
export const CLEAR_MINYANIM_TYPES = 'clearMinyanimTypes'
export const CLEAR_MINYANIM_TYPES_ISLOADING = 'clearMinyanimTypesIsLoading'

// stats
export const GET_DASHBOARD_STATS = 'getDashboardStats'
export const CLEAR_STATS_ISLOADING = 'clearDonationIsLoading'

// error 
export const CLEAR_ERRORMSG = 'clearErrorMessage'
export const UPDATE_ERRORMSG = 'updateErrorMessage'