export const DASHBOARD_ROUTE = 'DASHBOARD_ROUTE'
export const DISPLAY_ROUTE = 'DISPLAY_ROUTE'
export const LOGIN_ROUTE = 'LOGIN_ROUTE'
export const DONATIONS_ROUTE = 'DONATIONS_ROUTE'
export const SCREENS_ROUTE = 'SCREENS_ROUTE'
export const TEMPLATES_ROUTE = 'TEMPLATES_ROUTE'
export const ANNOUNCEMENTS_ROUTE = 'ANNOUNCEMENTS_ROUTE'
export const ORGANIZATIONS_ROUTE = 'ORGANIZATIONS_ROUTE'
export const ORGANIZATION_DETAILS_ROUTE = 'ORGANIZATION_DETAILS_ROUTE'
export const ORGANIZATION_ACCOUNTS_ROUTE = 'ORGANIZATION_ACCOUNTS_ROUTE'
export const ORGANIZATION_DONATIONS_ROUTE = 'ORGANIZATION_DONATIONS_ROUTE'
export const ORGANIZATION_MINYANIM_ROUTE = 'ORGANIZATION_MINYANIM_ROUTE'
export const ORGANIZATION_ANNOUNCEMENTS_ROUTE = 'ORGANIZATION_ANNOUNCEMENTS_ROUTE'
export const ORGANIZATION_SCREENS_ROUTE = 'ORGANIZATION_SCREENS_ROUTE'
export const ORGANIZATION_MEMBERS_ROUTE = 'ORGANIZATION_MEMBERS_ROUTE'
export const ORGANIZATION_TEMPLATES_ROUTE = 'ORGANIZATION_TEMPLATES_ROUTE'
export const ORGANIZATION_NOTIFICATIONS_ROUTE = 'ORGANIZATION_NOTIFICATIONS_ROUTE'
export const ALERT_ROUTE = 'ALERT_ROUTE'
export const TICKETS_ROUTE = 'TICKETS_ROUTE'
export const ACCOUNT_ROUTE = 'ACCOUNT_ROUTE'