import axios, { AxiosResponse } from 'axios'
import router from '../../router'
import { SET_ACCOUNT, SET_SESSION_TOKEN, SET_ERRORMSG } from '../mutation_types'
import {
    LOGIN,
    SIGNUP,
    UPDATE_ACCOUNT,
    GET_AUTH_ACCOUNT,
    CLEAR_ACCOUNT_ISLOADING,
    LOGOUT,
} from '../action_types'
import { StateObject } from '@/dtos/state'
import Auth from '@/services/auth'
import { LoginCredentals, RegistrationCredentals } from '@/dtos/auth.dto'
import { Module } from 'vuex'
import { EditUser, GetUser } from '@/dtos/users.dto'
import User from '@/services/user'

const account: Module<any, {}> = {
    state: {
        account: {
            data: null,
            status: null,
            isLoading: false
        },
        sessionToken: null
    },
    getters: {
        account: (state: { account: any }) => state.account,
        sessionToken: (state: { sessionToken: any }) => state.sessionToken
    },
    mutations: {
        [SET_ACCOUNT]: (state: { account: StateObject }, payload: { data: null; status: any; isLoading: any }) => { payload.data != null ? state.account.data = payload.data : null, state.account.status = payload.status, state.account.isLoading = payload.isLoading },
        [SET_SESSION_TOKEN]: (state: { sessionToken: any }, payload: any) => { state.sessionToken = payload },
    },
    actions: {
        [LOGIN]: async({ commit }: any, payload: any) => {
            commit(SET_ACCOUNT, { data: null, isLoading: true })

            let result: AxiosResponse = await Auth.login(payload as LoginCredentals)
          
            if(result) {
                if (result.data.status) {
                    console.log(result.data)
                    commit(SET_SESSION_TOKEN, result.data.sessionToken)
                    commit(SET_ACCOUNT, { data: result.data.data, isLoading: false })
                    return true;
                }
                commit(SET_ACCOUNT, { data: null, isLoading: false })
                commit(SET_ERRORMSG, result.data.msg)
                return false;
            } else {
                commit(SET_ACCOUNT, { isLoading: false })
                return false;
            }
        },
        [SIGNUP]: async({ commit }: any, payload: any) => {

            commit(SET_ACCOUNT, { data: null, isLoading: true })

            let result: AxiosResponse = await Auth.signup(payload as RegistrationCredentals)
          
            if(result) {
                if (result.data.status) {
                    commit(SET_SESSION_TOKEN, result.data.sessionToken)
                    commit(SET_ACCOUNT, { data: result.data.data, isLoading: false })
                    return true;
                }
                commit(SET_ACCOUNT, { data: null, isLoading: false })
                commit(SET_ERRORMSG, result.data.msg)
                return false;
            } else {
                commit(SET_ACCOUNT, { isLoading: false })
                return false;
            }
        },
        [UPDATE_ACCOUNT]: async({ commit }: any, payload: any) => {

            commit(SET_ACCOUNT, { isLoading: true })

            let result: AxiosResponse = await Auth.updateAccount(payload as EditUser)
          
            if(result) {
                if (result.data.status) {
                    commit(SET_ACCOUNT, { data: result.data.data, isLoading: false })
                    return true;
                }
                commit(SET_ERRORMSG, result.data.msg)
                return false;
            } else {
                commit(SET_ACCOUNT, { isLoading: false })
                return false;
            }
        },
        [GET_AUTH_ACCOUNT]: async({ commit, state }: any, payload: any) => {

            commit(SET_ACCOUNT, { isLoading: true })

            let result: AxiosResponse = await User.getUsers({ id: state.account.data.id } as GetUser)
          
            if(result) {
                if (result.data.status) {
                    commit(SET_ACCOUNT, { data: result.data.data[0], isLoading: false })
                    return true;
                }
                commit(SET_ERRORMSG, result.data.msg)
                return false;
            } else {
                commit(SET_ACCOUNT, { isLoading: false })
                return false;
            }
        },
        [LOGOUT]: async({ commit }) => {
            commit(SET_SESSION_TOKEN, null)
            commit(SET_ACCOUNT, { data: null, isLoading: false })
            return true
        },
        [CLEAR_ACCOUNT_ISLOADING]: ({ commit }) => {
            commit(SET_ACCOUNT, { isLoading: false })
        }
    }
}

export default account